.wrapper {
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  padding: 48px 0;
  position: relative;
  z-index: 2;

  .description {
    font-size: 18px;
    margin-bottom: 48px;
  }

  .cycle {
    width: 70vw;
    padding-bottom: 48px;
  }
}
