.wrapper {
  position: relative;
  background: url('./carbon.png') center center no-repeat;
  background-size: cover;

  &::after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 1;
  }

  .imgWrapper {
    z-index: 2;
  }
}
