.card {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  border-radius: var(--border-radius);
  box-shadow: 20px 15px 50px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  cursor: pointer;
  background-color: #fff;

  .image_cover {
    width: 552px;
    height: 371px;
  }

  .content {
    flex: 1;
    padding: 20px 24px 24px;
    transition: 0.3s ease-in-out;

    .top {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 32px;

      .time {
        color: var(--blue-color);
        font-size: 22px;
        font-weight: 500;
      }

      .arrow {
        width: 48px;
        height: 40px;
        background-color: var(--blue-color);
        border-radius: var(--border-radius);
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;

        img {
          height: 14px;
          margin-right: 10px;
        }
      }
    }

    .title {
      color: var(--title-font-color);
      font-size: var(--text-font-size);
      font-weight: 500;
      line-height: 28px;
      word-break: break-word;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

  &.active {
    .content {
      background-color: var(--blue-color);
      transition: 0.3s ease-in-out;

      .top {
        .time {
          color: #fff;
        }

        .arrow {
          background: #fff;
        }
      }

      .title {
        color: #fff;
      }
    }
  }
}
