.wrapper {
  padding: 96px 10%;

  h2 {
    margin-bottom: 60px;
  }

  .main {
    gap: 25%;

    .imageWrapper {
      flex: 1;

      img {
        width: 100%;
      }
    }

    .content {
      flex: 2;
      gap: 40px;

      .cell {
        color: #09333f;

        span {
          font-size: 70px;
          font-weight: bolder;
          opacity: 0.2;
          background: linear-gradient(180deg, #00badd -28.77%, #00badd 134.25%);
          background: linear-gradient(
            180deg,
            color(display-p3 0.2902 0.7176 0.851) -28.77%,
            color(display-p3 0.2902 0.7176 0.851 / 0) 134.25%
          );
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          transform: translate(-60px, 40px);
        }

        h5 {
        }

        > div {
        }
      }
    }
  }
}
