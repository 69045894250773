.experienceProcess {
  background: rgba(130, 215, 237, 0.2);
  padding: 150px 10%;
  gap: 200px;

  h3 {
    margin-bottom: 48px;
  }

  .text {
    font-size: 26px;
  }

  .iconCare {
    width: 200px;
    margin-left: 250px;
  }

  .title {
    width: 70%;
    margin-bottom: 48px;
    text-align: right;
  }

  .IconProcess {
    width: 100%;
    transform: scale(1.2);
  }
}

.support {
  color: #fff;
  background: linear-gradient(0deg, color(display-p3 0 0 0 / 0.2) 0%, color(display-p3 0 0 0 / 0.2) 100%),
    linear-gradient(
      0deg,
      color(display-p3 0.5882 0.8353 0.9176 / 0.7) 0%,
      color(display-p3 0.5882 0.8353 0.9176 / 0.7) 100%
    );
  gap: 100px;
  padding: 100px;

  .abstract {
    font-size: 24px;
    text-align: center;
    padding: 0 10%;
  }

  h2 {
    margin-bottom: 0;
  }

  .card {
    border-radius: 32px;
    background: rgba(255, 255, 255, 0.8);
    color: #09333f;
    padding: 80px 50px 80px 80px;

    h4 {
      margin-bottom: 68px;
      text-align: center;
    }

    .grid {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 80px 120px;

      .item {
        position: relative;

        .iconCircle {
          width: 80px;
          position: absolute;
          transform: translate(-45%, -40%);
        }

        .label {
          font-size: 24px;
          font-weight: 500;
        }
      }
    }

    .icon {
      width: 120px;
      margin-bottom: 40px;
    }
  }

  .bottom {
    h2 {
      text-align: right;
      margin-bottom: 48px;
    }

    .bottomContent {
      gap: 48px;

      .card {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;

        > div {
          font-size: 24px;
          font-weight: 500;
          margin-bottom: 8px;
        }
      }
    }
  }
}
