@import '@/assets/animation.scss';

:root {
  --primary-font-color: #2a1c1b;
  --gray-font-color: #4e5969;
  --title-font-color: #000f2d;
  --blue-color: #165dff;
  --page-background-color: #f9fbfc;
  --black-background-color: #2a1c1b;
  --gradient-background-color: linear-gradient(
    -45.56deg,
    rgba(5.31, 120.17, 255, 1) 6.351%,
    rgba(21.25, 212.93, 255, 1) 109.129%
  );

  --title-font-size: 48px;
  --subtitle-font-size: 32px;
  --strong-font-size: 24px;
  --text-font-size: 20px;
  --border-radius: 2px;
  --min-width: 640px;

  --animation-fade-in: fadeIn 0.3s ease-in-out;
  --animation-fade-out: fadeOut 0.3s ease-in-out;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: var(--min-width);
  overflow-x: overlay;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

h2 {
  font-size: 64px !important;
  font-weight: 700 !important;
}

h3 {
  font-size: 56px !important;
  font-weight: 600 !important;
}

h4 {
  font-size: 48px !important;
  font-weight: 600 !important;
}

h5 {
  font-size: 32px !important;
  font-weight: 600 !important;
}
