.image {
  width: 100%;
  aspect-ratio: 1440/810;
  object-fit: cover;
  object-position: top;
}

.caption {
  bottom: 52% !important;
  transform: translateY(20%);
  padding: 0 !important;
  max-width: 900px;
  margin: auto;
  animation: var(--animation-fade-in);

  h2 {
    color: var(--primary-font-color);
    font-size: var(--title-font-size);
    font-weight: 500;
    margin-bottom: 60px;
  }

  .description {
    color: var(--primary-font-color);
    font-size: 24px;
  }

  .button {
    display: inline-block;
    line-height: 46px;
    font-size: var(--primary-font-size);
    background-color: var(--blue-color);
    text-align: center;
    margin: 0 auto;
    padding: 0 24px;
  }
}
