.wrapper {
  aspect-ratio: 5/3;
  position: relative;
  background: url('./background.webp') center bottom no-repeat, linear-gradient(180deg, #eff0f0 0%, #fff 61.45%);

  &.shadow::before {
    position: absolute;
    left: 0;
    top: 0;
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background: url('./background.webp') center bottom no-repeat,
      linear-gradient(180deg, #2a1c1b 0%, rgba(21, 14, 13, 0.62) 39.27%, #eff0f0 125.11%), #eff0f0;
    background-size: contain;
  }

  .card {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    transform: translateY(-100%);
    width: 55vw;
    max-width: 1000px;
    height: fit-content;
    background-color: rgba(255, 255, 255, 0.9);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 36px;
    padding: 48px 30px;
    border-radius: 32px;
    text-align: center;

    .arrow {
      width: 68px;
      height: 68px;
      padding: 24px;
      border-radius: 50%;
      border: 1px solid #000;
      margin-top: 32px;
      cursor: pointer;
    }
  }
}
