.application {
  width: 100%;
  margin: 0 auto;
  padding: 96px 0;

  h2 {
    margin-bottom: 48px;
    text-align: center;
    text-transform: capitalize;
  }

  .slideWrapper {
    &.center {
      padding: 0 8%;

      > div {
        display: flex;
        justify-content: center;
      }
    }
  }

  .slide {
    display: flex;
    align-items: flex-end;
    width: 100%;
    aspect-ratio: 400/500;
    border-radius: 32px;
    overflow: clip;
    color: #fff;
    padding: 20px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .textContainer {
      position: relative;
    }
  }

  .content {
  }
}
