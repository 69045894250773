.anchorPoint {
  display: flex;
  justify-content: center;
  gap: 40px;
  padding: 20px 60px;
  border-bottom: 1px solid #d5d5d5;

  > div {
    font-size: 18px;
    text-transform: capitalize;
    cursor: pointer;
    transition: color 0.3s ease-in-out;

    &:hover {
      color: #3f93db;
    }
  }
}
