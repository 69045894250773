.footer {
  background-color: #0c0f13;
  padding: 24px 300px 100px 240px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .left {
    .social {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 32px;

      img {
        width: 48px;

        & + img {
          margin-left: 24px;
        }
      }
    }
  }

  .right {
    display: flex;
    flex-direction: row;
    padding-left: 150px;

    .column {
      display: flex;
      flex-direction: column;

      & + .column {
        margin-left: 78px;
      }

      .title {
        color: #fff;
        font-size: var(--text-font-size);
        cursor: pointer;
      }

      .children {
        margin-top: 32px;

        .item {
          color: #c9cdd4;
          font-size: 18px;
          margin-bottom: 32px;
          cursor: pointer;
        }
      }
    }
  }
}
