.wrapper {
  padding: 96px 10%;

  h2 {
    text-align: center;
    margin-bottom: 60px;
  }
}

.list {
  width: 100%;
  gap: 60px;
}

.listItemWrapper {
  min-width: 0;
  flex: 0 0 calc(33.33% - 30px);

  .productImage {
    width: 100%;
    aspect-ratio: 1;
    object-fit: contain;
  }

  .content {
    min-width: 0;
    width: 100%;
    text-align: center;

    h5 {
      white-space: noWrap;
      text-overflow: ellipsis;
      overflow: hidden;
      margin-top: 32px;
    }

    .description {
      line-height: 28px;
      margin-top: 32px;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      height: 84px;
    }

    .more {
      width: 60px;
      height: 60px;
      box-sizing: content-box;
      border: 1px solid #000;
      border-radius: 50%;
      margin: 32px auto 0;
      display: flex;
      align-items: center;
      justify-content: center;

      .img {
      }
    }
  }
}
