.wrapper {
  .title {
    text-align: center;
    margin-bottom: 48px;
  }
}

.accordion {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  gap: 2%;
  padding: 20px 60px;
  cursor: pointer;
  height: 800px;

  .item {
    flex: 1;
    position: relative;
    overflow: hidden;
    height: 100%;
    border-radius: 30px;
    opacity: 0.9;
    will-change: flex;
    padding: 30px 15px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    transition: flex 0.6s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.3s ease;

    .staticTitle {
      word-break: break-word;
      color: #fff;
      transition: opacity 0.2s ease 0s;
    }

    .content {
      opacity: 0;
      color: #fff;
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 40px;
      transform: translateY(100%);
      transition: opacity 0.4s ease 0.2s, transform 0.4s cubic-bezier(0.4, 0, 0.2, 1) 0.2s;
      background: linear-gradient(to top, rgba(0, 0, 0, 0.4) 20%, rgba(0, 0, 0, 0) 100%);

      .viewMore {
        width: fit-content;
        border: 1px solid #fff;
        border-radius: 40px;
        padding: 10px 48px;
        margin-top: 30px;
      }
    }

    &.active {
      flex: 4;
      opacity: 1;
      transition: flex 0.6s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.3s ease;

      .staticTitle {
        opacity: 0;
        transition-duration: 0.1s;
      }

      .content {
        opacity: 1;
        transform: translateY(0);
        transition: opacity 0.4s ease 0.2s, transform 0.4s cubic-bezier(0.4, 0, 0.2, 1) 0.2s;
        transition-delay: 0.5s;
      }
    }

    &:not(.active) {
      .staticTitle {
        opacity: 1;
        transition: opacity 0.2s ease 0s;
        transition-delay: 0.5s;
      }

      .content {
        opacity: 0.5;
        transition: opacity 0.3s ease 0s, transform 0.5s ease 0s;
      }
    }
  }
}
