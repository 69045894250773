/* SwiperCarousel.module.css */
.container {
  position: relative;
  padding: 40px 0;
  max-width: 85vw;
  margin: 0 auto;
}

.slide {
  opacity: 0.9;
  transform: scale(0.85);
  transition: all 0.4s ease;
  filter: brightness(0.95);
  height: auto !important; /* 覆盖默认高度 */
}

.active {
  opacity: 1 !important;
  transform: scale(1);
  z-index: 1;
  filter: brightness(1);
}

.content {
  background: white;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  min-height: 300px;
  transition: transform 0.3s ease;
}

.navigation {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 50px;
  height: 50px;
  background: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 10;
  font-size: 40px;
  user-select: none;
}

.prev {
  left: 20px;
}

.next {
  right: 20px;
}

/* 响应式设计 */
@media (max-width: 768px) {
  .slide {
    transform: scale(0.95);
  }

  .active {
    transform: scale(1);
  }

  .navigation {
    width: 40px;
    height: 40px;
    font-size: 30px;
  }
}

.title {
  font-size: 1.5rem;
  margin-bottom: 0;
}

.position {
  font-size: 16px !important;
  margin-bottom: 20px;
}

.description {
  font-size: 1rem;
  line-height: 1.6;
  color: #666;
  height: 400px;
  overflow-y: auto;
  // word-break: break-word;
  // display: -webkit-box;
  // -webkit-line-clamp: 13;
  // -webkit-box-orient: vertical;
  // overflow: hidden;
}
