.container {
  position: relative;
  padding: 48px 96px;
  animation: var(--animation-fade-in);

  .main {
    display: flex;
    flex-direction: column;
  }

  .tabs {
    margin-bottom: 20%;

    .tabItem {
      flex: 1;
      color: rgba(255, 255, 255, 0.7);
      font-size: 24px;
      text-align: center;
      cursor: pointer;
      transition: all 0.3s ease-in-out;

      &::after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: -20px;
        margin: auto;
        width: 30%;
        height: 3px;
        border-radius: 10px;
        background-color: rgba(255, 255, 255, 0.7);
        transform: scaleX(0); /* 初始宽度为0 */
        transform-origin: center; /* 从中心扩展 */
        transition: transform 0.3s ease; /* 动画效果 */
      }

      &.active {
        position: relative;
        color: #fff;
        font-weight: 500;

        &::after {
          color: #fff;
          transform: scaleX(1); /* 扩展到完整宽度 */
        }
      }
    }
  }

  .content {
    height: fit-content;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    color: #fff;
    width: 50%;
    gap: 32px;
    text-align: center;
    animation: var(--animation-fade-in);

    .title {
      img {
        width: 64px;
        margin-right: 16px;
      }
    }

    .description {
      font-size: var(--text-font-size);
    }

    .moreButton {
      border: 1px solid #fff;
      border-radius: 50px;
      padding: 14px 80px;
      cursor: pointer;
    }
  }
}
