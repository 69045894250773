.news {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.textLeft {
  text-align: left;
}

.moduleWrapper {
  color: #fff;
  padding: 96px 0;

  h2 {
    text-transform: capitalize;
  }
}

.bottomWrapper {
  position: relative;

  &::before {
    position: absolute;
    left: 0;
    top: 0;
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background: url('../../../public/assets/bottom-background.webp') no-repeat center bottom;
    background-size: 100% auto;
    transform: translateY(18%);
  }
}
