.wrapper {
  position: relative;
  aspect-ratio: 1440/810;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;

  .title {
    width: 50%;
    text-align: center;
  }

  .bottom {
    width: 100%;
    margin-top: auto;
    padding: 96px 0;

    > div {
      flex: 0 0 20%;
      gap: 40px;

      > img {
        width: 120px;
      }
    }

    .label {
      font-size: 32px;
      font-weight: 500;
      line-height: 1.2;
      text-align: center;
    }
  }
}
