.wrapper {
  padding: 96px 10%;

  h2 {
    text-align: center;
    margin-bottom: 48px;
  }

  img {
    width: 100%;
  }
}
