.nav {
  font-size: 16px;
  background: transparent !important;
  position: sticky !important;
  z-index: 99;
  transition: 0.15s ease-in-out;
  padding: 24px 100px !important;

  &.soul {
    position: fixed !important;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 100%;
    min-width: var(--min-width);

    > * {
      color: #fff;
    }

    :global(.nav-link) {
      color: #fff;
    }

    &.dark {
      > * {
        color: var(--primary-font-color);
      }

      :global(.nav-link) {
        color: var(--primary-font-color);
      }
    }

    :global(.navbar-toggler) {
      // color: #fff !important;
    }
  }

  &.sticky {
    position: sticky;
    top: 0;
    background-color: #fff !important;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    transition: 0.3s ease-in-out;

    > * {
      color: var(--gray-font-color);
    }

    :global(.nav-link) {
      color: var(--gray-font-color);
    }

    :global(.dropdown-toggle.show) {
      color: var(--blue-color) !important;
    }
  }

  :global(.nav-link) {
    cursor: pointer;
    font-size: 20px;
    padding-right: 28px !important;
    padding-left: 28px !important;
  }

  :global(.nav-link:hover) {
    color: var(--blue-color);
  }

  :global(.dropdown-item) {
    font-size: 14px;
  }

  :global(.dropdown-toggle::after) {
    vertical-align: middle;
    margin-left: 8px;
  }

  .language {
    width: 220px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: bolder;

    .text {
      text-transform: uppercase;
    }
  }
}
