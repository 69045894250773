.darkMask {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.2);
  }

  > * {
    position: relative;
    z-index: 2;
  }
}

.bannerWrapper {
  aspect-ratio: 1440/810;
  color: #fff;
  padding: 0 20%;
  text-align: center;

  h2 {
    margin-bottom: 40px;
  }
}

.wrapper {
  aspect-ratio: 1440/810;
  color: #fff;
  padding: 150px 13%;

  h2 {
    width: 360px;
    text-align: right;
  }

  .info {
    min-width: 0;
    flex: 1;
    margin-left: 100px;
  }

  .gridCard {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 60px;
    margin-top: 100px;

    .card {
      color: var(--primary-font-color);
      border-radius: 32px;
      background: rgba(255, 255, 255, 0.8);
      gap: 12px;
      padding: 80px 80px 60px;
      text-align: center;

      img {
        width: 100px;
      }
    }
  }
}

.imageWrapper {
  aspect-ratio: 1440/810;
  color: #fff;
  padding: 100px 10%;

  h2 {
    margin-bottom: 20px;
  }

  > div {
    width: 70%;
  }

  &.right {
    align-items: flex-end;
    text-align: right;
  }

  &.left {
    align-items: flex-start;
  }
}

.storyWrapper {
  padding: 250px 10%;
  gap: 150px;
  background-size: cover;

  img {
    width: 600px;
    aspect-ratio: 27/25;
    object-fit: cover;
    border-radius: 32px;
  }

  h2 {
    text-transform: capitalize;
    text-align: right;
    margin-bottom: 32px;
  }

  .storyContent {
    min-width: 0;
    gap: 20px;

    div {
      text-align: right;
    }
  }
}

.teamWrapper {
  background-color: #eff0f0;
  padding: 150px 10% 0;

  .top {
    text-align: center;

    h2 {
      margin-bottom: 48px;
    }

    div {
      font-size: 28px;
    }
  }

  .team {
    width: 100%;
    margin: 96px 0 0;
  }
}
