.wrapper {
  .title {
    text-align: center;
    margin-bottom: 48px;
  }

  .main {
    padding: 40px;
  }
}

.earthMap {
  min-width: 0;
  width: fit-content;
  position: relative;
  display: flex;

  > img {
    width: 35vw;
  }
}

.pointWrapper {
  cursor: pointer;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 50px;
  height: 50px;

  .coordinate {
    width: 50px;
    visibility: hidden;
    position: absolute;
    padding-bottom: 30px;

    &.visible {
      visibility: visible;
      animation: var(--animation-fade-in);
    }
  }

  .point {
    visibility: hidden;
    position: absolute;

    &.visible {
      visibility: visible;
      animation: var(--animation-fade-in);
    }

    .circle {
      width: 24px;
      height: 24px;
      border-radius: 100%;
      position: relative;

      > div {
        border-radius: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translateX(-50%) translateY(-50%);
        transform: translateX(-50%) translateY(-50%);

        &:nth-child(1) {
          width: 35%;
          height: 35%;
          background: #d4161d;
          z-index: 9;
        }
        &:nth-child(2) {
          width: 100%;
          height: 100%;
          background: #d4161d;
          animation: banner_scale 2.4s linear infinite normal both;
        }
        &:nth-child(3) {
          width: 100%;
          height: 100%;
          background: #d4161d;
          animation: banner_scale 2.4s linear infinite normal both;
        }
        &:nth-child(4) {
          width: 100%;
          height: 100%;
          background: #d4161d;
          animation: banner_scale 2.4s 0.8s linear infinite normal both;
        }
        &:nth-child(5) {
          width: 100%;
          height: 100%;
          background: #d4161d;
          animation: banner_scale 2.4s 1.6s linear infinite normal both;
        }
      }
    }
  }
}

@keyframes banner_scale {
  0% {
    width: 100%;
    height: 100%;
    opacity: 0;
  }
  20% {
    width: 120%;
    height: 120%;
    opacity: 0.3;
  }
  40% {
    width: 140%;
    height: 140%;
    opacity: 0.2;
  }
  60% {
    width: 160%;
    height: 160%;
    opacity: 0.1;
  }
  80% {
    width: 20%;
    height: 20%;
    opacity: 0;
  }
  100% {
    width: 100%;
    height: 100%;
    opacity: 0;
  }
}

.right {
  width: 28vw;
  transform: translateY(-8%);

  .board {
    width: fit-content;
    min-width: 90%;
    position: relative;
    border-radius: 32px;
    padding: 30px 40px;
    border-radius: 32px;
    background: linear-gradient(0deg, rgba(239, 240, 240, 0.2) 0%, #898a8a 152.5%);
    background: linear-gradient(
      0deg,
      color(display-p3 0.9373 0.9412 0.9412 / 0.2) 0%,
      color(display-p3 0.5389 0.5412 0.5412 / 0) 152.5%
    );
    backdrop-filter: blur(2px);
    animation: var(--animation-fade-in);

    & + .board {
      margin-top: 42px;
    }

    .label {
      color: #fff;
      margin-bottom: 16px;
    }

    .info {
      font-size: 16px;
      gap: 60px;

      .type {
        color: rgba(255, 255, 255, 0.6);
      }
    }
  }

  &.one {
    .board {
      transform: translateX(10%);
    }
  }

  &.two {
    .board {
      &:nth-child(1) {
        transform: translateX(30%);
      }

      &:nth-child(2) {
        transform: translateX(10%);
      }
    }
  }

  &.three {
    .board:nth-child(1) {
      transform: translateX(15%);
    }

    .board:nth-child(2) {
      transform: translateX(30%);
    }

    .board:nth-child(3) {
      transform: translateX(5%);
    }
  }
}
