.brand {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  margin-right: 40px;

  .logo {
    width: 32px;
    height: 32px;
  }

  .name {
    width: 70px;
  }
}
